import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Element, Link } from 'react-scroll';

import Layout from '../components/layout';
import heroImage from '../images/homepage_hero.jpg';

const IndexPage = ({
  data: {
    file: {
      childImageSharp: { fluid: brentFluid },
    },
  },
}) => {
  return (
    <Layout>
      <Element name={''}>
        <div
          style={{
            background: `url(${heroImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'none',
            backgroundPosition: 'center center',
          }}
        >
          <div className="pt-4 pb-5" style={{ background: `rgba(0,0,0,.16)` }}>
            <div className="container py-5">
              <h1
                className="display-4 text-spread text-uppercase text-white font-weight-bold mt-5"
                style={{ textShadow: '0 2px 16px rgba(0,0,0,.25)' }}
              >
                Your Smile Is Worth It
              </h1>
              <h2
                className="h5 text-white w-75 mb-4"
                style={{
                  textShadow: '0 2px 16px rgba(0,0,0,.25)',
                  lineHeight: '1.5',
                }}
              >
                At Dickenson Dental, we'll help you keep your smile looking its
                very best. Come visit our Knoxville office today!
              </h2>
              <Link
                to="contact"
                smooth={true}
                offset={-125}
                className="btn btn-light mb-5 py-2 px-3 text-uppercase"
              >
                <span className="">Book an appointment</span>
              </Link>
            </div>
          </div>
        </div>
      </Element>
      <div className="container mt-3 py-5">
        <div className="row">
          <div className="col-md-4 mb-3 text-center px-4">
            <h3 className="text-primary h4 mb-3">General Dentistry</h3>
            <p className="text-secondary">
              Most people are only in need of routine services such as regular
              cleanings every six months. These preventative services are what
              keep your smile looking it’s best for your entire life.
            </p>
          </div>
          <div className="col-md-4 mb-3 text-center px-4">
            <h3 className="text-primary h4 mb-3">Family Dentistry</h3>
            <p className="text-secondary">
              The whole family is welcome at Dickenson Dental. Our goal is to
              build amazing, long lasting relationships with each of our
              patients. We want every patient to feel like part of our family.
            </p>
          </div>
          <div className="col-md-4 mb-3 text-center px-4">
            <h3 className="text-primary h4 mb-3">Cosmetic Dentistry</h3>
            <p className="text-secondary">
              From simple teeth whitening specials and implant restoration, to
              full mouth reconstruction, we have what it takes to create
              beautiful bright smiles.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="py-5 col-md-6 bg-secondary text-white text-center px-3 px-md-5">
            <h4>Need a dentist?</h4>
            <p className="mb-4">
              We are currently accepting new patients and would love to meet
              you!
            </p>
            <Link
              to="contact"
              smooth={true}
              offset={-125}
              className="btn btn-outline-light btn-lg"
            >
              Contact Us
            </Link>
          </div>
          <div className="py-5 col-md-6 bg-primary text-white text-center px-3 px-md-5">
            <h4>Don't take it from us</h4>
            <p className="mb-4">
              Read what others are saying about their experience.
            </p>
            <a
              href="https://www.google.com/search?q=Dickenson+Dental,+2620+Mineral+Springs+Avenue,+%23B,+Knoxville,+TN+37917&ludocid=7130315469844674448#lrd=0x885c160edf8bdd03:0x62f3f8244c907790,1"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-light btn-lg"
            >
              Read Reviews
            </a>
          </div>
        </div>
      </div>
      <Element name="about">
        <div
          className="about-bg"
          style={{
            background: `url(${brentFluid.src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        >
          <div className="container py-5">
            <div className="row ">
              <div className="col d-lg-none">
                <Img fluid={brentFluid} />
              </div>
              <div className="col-lg-6">
                <h2 className="my-4">Meet Dr. Brent</h2>
                <p>
                  Dr. Brent Dickenson was born and raised in Knoxville,
                  Tennessee. He attended Bearden High School, where he met and
                  eventually married his high school sweetheart, Eliza. After
                  high school, Dr. Dickenson received his undergraduate degree
                  at The University of Tennessee, and obtained his Doctor of
                  Dental Surgery at the University of Tennessee Health Science
                  Center in Memphis, TN.
                </p>
                <p>
                  After graduation, Dr. Dickenson and his family moved back to
                  Knoxville, where he practiced for 4 years in the West
                  Knoxville area. Dr. Dickenson and his wife, Eliza have two
                  children, Webb (4) and Grace (almost 2). They are members of
                  Cedar Springs Presbyterian Church in Knoxville. When he is not
                  at work caring for his patients and helping meet all their
                  dental needs, Dr. Dickenson enjoys spending time with his
                  family, exercising, and cooking on his Big Green Egg!
                </p>
                <p>
                  Dr. Dickenson’s professional affiliations include the American
                  Dental Association, the Tennessee Dental Association, and the
                  Pankey Alumni Association. The Pankey Institute is a dental
                  continuing education center in Key Biscayne, FL, where Dr.
                  Dickenson has acquired over 200 hours of additional training
                  in all aspects of dentistry.{' '}
                </p>
                <p className="font-weight-bold font-italic">
                  Dr. Dickenson and the entire team at Dickenson Dental are
                  eager to help you maintain your dental health and keep you
                  smiling with confidence!
                </p>
              </div>
            </div>
          </div>
        </div>
      </Element>
      {/*<Element name="services">*/}
      {/*  <div className="container my-5 py-5">*/}
      {/*    <div className="h3 my-4">Our Services</div>*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-md-4 my-5">*/}
      {/*        <h5 className="card-title text-primary">Teeth Whitening</h5>*/}
      {/*      </div>*/}
      {/*      <div className="col-md-4 my-5">*/}
      {/*        <h5 className="card-title text-primary">Cleaning</h5>*/}
      {/*      </div>*/}
      {/*      <div className="col-md-4 my-5">*/}
      {/*        <h5 className="card-title text-primary">Kid Friendly!</h5>*/}
      {/*      </div>*/}
      {/*      <div className="col-md-4 my-5">*/}
      {/*        <h5 className="card-title text-primary">Teeth Whitening</h5>*/}
      {/*      </div>*/}
      {/*      <div className="col-md-4 my-5">*/}
      {/*        <h5 className="card-title text-primary">Teeth Whitening</h5>*/}
      {/*      </div>*/}
      {/*      <div className="col-md-4 my-5">*/}
      {/*        <h5 className="card-title text-primary">Teeth Whitening</h5>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Element>*/}
      <Element name="location" className="bg-light pt-5">
        <div className="container py-5">
          <h2>Conveniently located in the heart of Knoxville</h2>
          <p>
            Our locations is easily reached from West Knoxville, North
            Knoxville, Downtown Knoxville and East Knoxville.
          </p>
          <p className="mt-4">Address:</p>
          <div className="font-weight-bold">
            <p className="mb-1">2620 Mineral Springs Avenue, #B</p>
            <p className="mb-1">Knoxville, TN 37917</p>
            <p>865-688-4442</p>
          </div>
        </div>
        <div className="row mt-5 no-gutters">
          <div className="col">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3227.1892422628857!2d-83.92101538461074!3d36.01567191916444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885c160edf8bdd03%3A0x62f3f8244c907790!2sDickenson+Dental!5e0!3m2!1sen!2sus!4v1555267760305!5m2!1sen!2sus"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
            />
          </div>
        </div>
      </Element>
      <Element name="contact">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-md-6 py-5">
              <h2 className="h2">Contact Us Today</h2>
              <p className="mb-4">
                Our team is here to serve you and we are actively taking new
                patients. Please fill out the form below and we'll get back to
                you as soon as possible.
              </p>
              <form name="contact-form" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="contact-form" />
                <div className="form-group">
                  <label>Name</label>
                  <input
                    required
                    name="name"
                    id="name"
                    className="form-control"
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    required
                    name="email"
                    id="email"
                    className="form-control"
                    type="email"
                  />
                </div>
                <div className="form-group">
                  <label>Phone</label>
                  <input
                    required
                    name="phone"
                    id="phone"
                    className="form-control"
                    type="tel"
                  />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    id="message"
                    name="message"
                    className="form-control"
                  />
                </div>
                <button type="submit" className="btn btn-primary btn-lg my-4">
                  Let's Talk!
                </button>
              </form>
            </div>
          </div>
        </div>
      </Element>
      <footer className="py-5 text-center bg-light small text-uppercase text-secondary">
        © {new Date().getFullYear().toString()} Dickenson Dental
      </footer>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    file(name: { eq: "brent2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
